import dynamic from 'next/dynamic';

const OfferCardFiltersDropdown = dynamic(() => import('.'));

const DynamicallyLoadedFiltersDropdown = ({
  isInternationalOffer,
  subcategoryName,
  categoryName,
  ...rest
}) => {
  if (!(categoryName || subcategoryName || isInternationalOffer)) {
    return null;
  }

  return (
    <OfferCardFiltersDropdown
      categoryName={categoryName}
      isInternationalOffer={isInternationalOffer}
      subcategoryName={subcategoryName}
      {...rest}
    />
  );
};

export default DynamicallyLoadedFiltersDropdown;
